// Show login.tsx

import { MetaFunction } from "@remix-run/node";
import LandingPage, { loader as landingLoader } from "~/routes/landing";

export const loader = landingLoader;

export const meta: MetaFunction = () => {
  return [{ title: "Vendur - Walmart Repricer" },
    { name: "description", content: "Welcome to Vendur!" },
    { name:"og:title", content:"Walmart Repricer & Profit Manager"},
    // Currently added to root so all pages get the same image
    // { name:"og:image", content:"https://vendur.io/images/official_vendur_logo.png"},
  ];
};

export default LandingPage;
